<template>
  <div class="common-page">
    <div class="common-search-box" style="padding-right:230px">
      <el-form :inline="true" class="demo-form-inline" :size="$store.state.settingInfo.size">
        <el-form-item label="小区" >
          <el-select v-model="searchParams.depId" placeholder="请选择小区" clearable @change="onDeptChange">
            <el-option v-for="(item, index) in depList" :key="index" :label="item.depName" :value="item.depId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="月份" >
          <el-date-picker
            v-model="searchParams.year"
            type="year"
            format="yyyy"
            value-format="yyyy" 
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" >
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="onReset" >重置</el-button>
        </el-form-item>
      </el-form>
      <div class="common-export-btn"> 
        <el-button type="primary" :loading="btnLoading" @click="onExport" :size="$store.state.settingInfo.size">导出</el-button>
        <!-- <el-button type="primary" @click="onBatchExport" :size="$store.state.settingInfo.size">账单打印</el-button>
        <el-button type="primary" @click="onEdit" :size="$store.state.settingInfo.size">交费</el-button>
        <el-button type="primary" :loading="btnLoading" @click="onExport" :size="$store.state.settingInfo.size">导出催账单</el-button> -->
      </div>
    </div><div class="table" ref="table">
      <el-table
        border
        :data="tableData"
        :max-height="heights"
        :size="$store.state.settingInfo.size"
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          fixed="left"
          width="50">
        </el-table-column>
        <el-table-column
          prop="depName"
          width="120px"
          fixed="left"
          label="小区">
        </el-table-column>
        <el-table-column
          fixed="left"
          prop="month"
          width="80px"
          label="月份">
        </el-table-column>
        <el-table-column
          fixed="left"
          prop="roomCount"
          width="100px"
          label="住宅总户数">
        </el-table-column>
        <el-table-column
          prop="roomArea"
          width="100px"
          label="住宅总面积">
        </el-table-column>
        <el-table-column
          prop="roomAmount"
          width="110px"
          label="住宅应收物管费">
        </el-table-column>
        <el-table-column
          prop="shopCount"
          width="120"
          label="商铺总户数">
        </el-table-column>
        <el-table-column
          prop="shopArea"
          width="120"
          label="商铺总面积">
        </el-table-column>
        <el-table-column
          prop="shopAmount"
          width="120"
          label="商铺应收物管费">
        </el-table-column> 
        <el-table-column
          prop="totalCount"
          width="120"
          label="住宅和商铺总户数">
        </el-table-column>
        <el-table-column
          prop="totalArea"
          width="120"
          label="住宅和商铺总面积">
        </el-table-column>
        <el-table-column
          prop="totalAmount"
          width="160"
          label="住宅和商铺管理费总金额">
        </el-table-column>
        <el-table-column
          prop="idleNum"
          label="空闲户数">
        </el-table-column>
        <el-table-column
          prop="idleArea"
          width="120"
          label="空闲户数面积">
        </el-table-column>
        <el-table-column
          prop="idleAmount"
          width="160"
          label="空闲户数金额">
        </el-table-column>
        <el-table-column
          prop="notNum"
          width="120"
          label="达收楼条件未收楼户数">
        </el-table-column>
        <el-table-column
          prop="notArea"
          width="130"
          label="达收楼条件未收楼户数面积">
        </el-table-column>
        <el-table-column
          prop="notAmount"
          width="120"
          label="达收楼条件未收楼户数金额">
        </el-table-column>
        <el-table-column
          prop="discountNum"
          label="减免户数">
        </el-table-column>
        <el-table-column
          prop="discountArea"
          width="140"
          label="减免户数面积">
        </el-table-column>
        <el-table-column
          prop="discountAmount"
          width="140"
          label="减免户数金额">
        </el-table-column>
        <el-table-column
          prop="paidCount"
          width="140"
          label="应收管理费户数">
        </el-table-column>
        <el-table-column
          prop="paidArea"
          width="140"
          label="应收管理费面积">
        </el-table-column>
        <el-table-column
          prop="paidAmount"
          width="140"
          label="应收管理费金额">
        </el-table-column>
        <el-table-column
          prop="prepaidAmount"
          width="140"
          label="已预收管理费总额">
        </el-table-column>
        <el-table-column
          prop="payAmount"
          width="140"
          label="已收管理费总额">
        </el-table-column>
        <el-table-column
          prop="arrearsAmount"
          width="140"
          label="欠管理费金额">
        </el-table-column>
        <el-table-column
          prop="ratio"
          width="140"
          label="收占比例">
        </el-table-column>
        <el-table-column
          prop="arrearsCount"
          width="150"
          label="截至当月累计欠费户数">
        </el-table-column>
        <el-table-column
          prop="arrearsAccumulatedAmount"
          width="150"
          label="截至当月累计欠费金额">
        </el-table-column>
        <el-table-column
          prop="prepaidRemainingAmount"
          width="160"
          label="截至当月预交款剩余金额">
        </el-table-column>
        <!-- <el-table-column
          prop="paymentDate"
          label="支付状态">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.paymentState == 0">已缴费</span>
              <span v-if="scope.row.paymentState == 1">未缴费</span>
              <span v-if="scope.row.paymentState == 2">部分支付</span>
            </div>
          </template>
        </el-table-column>  -->
      </el-table>
    </div>
  </div>
</template>
<script>
import minxins from '@/minxins/index'
export default {
  data () {
    return {
      searchParams: {
        depId: '',
        year: '',
      },
      btnLoading: false,
      total: 0,
      tableData: [],
      depList: [],
    }
  },
  mixins: [minxins],
  mounted () {
    this.getAllDerp()
  },
  methods: {
    onDelete (params) {
      this.$confirm('是否删除数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$fetch.post(`/pay/deletePayRecord`, { payId: params.payId }).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.onSearch()
          }
        })
      })
    },
    onDeptChange () {
      // this.getBuildings(this.searchParams.depId)
      // this.getPaymentCycle(this.searchParams.depId)
    },
    onExport () {
      window.open(`/manage/statistics/exportRoomStatistics?year=${this.searchParams.year}&depId=${this.searchParams.depId}`)
      this.btnLoading = true
      this.$fetch.post('/statistics/exportRoomStatistics', {
        ...this.searchParams,
        limit: false,
      }, { 
        responseType: 'blob', 
      }).then(res => {
        this.btnLoading = false
        let name = this.tableData[0].departmentDto.depName + '_' + this.$moment(new Date()).format('YYYY-MM-DD') + '.xls'
        this.$utils.exportFile(res, name)
      })
    },
    getInfo () {
      this.$bus.$emit('createLoad', true)
      this.$fetch.get('/statistics/roomStatistics', {
        params: {
          ...this.searchParams
        }
      }).then(res => {
        this.$bus.$emit('createLoad', false)
        this.tableData = []
        this.total = 0
        if (res.code == 200 && res.result) {
          this.tableData = res.result
        }
      })
    },
    getAllDerp () {
      this.$fetch.post('/department/queryDeps', {
        limit: false
      }).then(res => {
        if (res.code == 200 && res.result) {
          this.depList = res.result.list
        }
      })
    },
    onSizChange (size) {
      this.searchParams.pageSize = size
      this.searchParams.pageNum = 1
      this.getInfo()
    },
    onCurrentChange (num) {
      this.searchParams.pageNum = num
      this.getInfo()
    },
    onReset () {
      for(let i in this.searchParams) {
        if (i != 'limit' && i != 'pageNum' && i != 'pageSize') {
          if (this.searchParams[i] instanceof Array) {
            this.searchParams[i] = []
          } else {
            this.searchParams[i] = ''
          }
        }
      }
    },
    onSearch () {
      this.getInfo()
    },
  }
}
</script>